<script>
import GeneralFilter from './General.vue'
import OrderIdsFilter from './OrderIds.vue'
import BulkAssignmentDialog from './BulkAssignmentDialog.vue'
import ShiftAssignmentDialog from './ShiftAssignmentDialog.vue'
import BatchAssignmentDialog from './BatchAssignmentDialog.vue'
import { mapActions, mapGetters } from 'vuex'
import { tabType } from '@/store/modules/dashboard/config'

export default {
	name: 'FormFilter',
	components: {
		GeneralFilter,
		OrderIdsFilter,
		BulkAssignmentDialog,
		BatchAssignmentDialog,
		ShiftAssignmentDialog
	},
	data() {
		return {
			tabType,
			openAssignDialog: false,
			openBatchAssignDialog: false,
			openShiftAssignDialog: false
		}
	},
	computed: {
		...mapGetters('dashboard', ['tab'])
	},
	beforeDestroy() {
		this.resetFilter()
	},
	methods: {
		...mapActions('dashboard', ['changeTab', 'resetFilter'])
	}
}
</script>

<template>
	<div>
		<q-tabs
			:value="tab"
			align="left"
			class="q-ma-none q-gutter-y-md dashboard-form-filter-tabs"
			content-class="q-ma-none"
			active-color="primary"
			dense
			@input="changeTab"
		>
			<q-tab :name="tabType.general" label="General" />
			<q-tab :name="tabType.orderIds" label="Order ID" />
		</q-tabs>
		<q-tab-panels v-model="tab">
			<q-tab-panel :name="tabType.general" class="q-px-none">
				<general-filter
					@open-assign-dialog="openAssignDialog = true"
					@open-batch-assign-dialog="openBatchAssignDialog = true"
					@open-shift-assign-dialog="openShiftAssignDialog = true"
				/>
			</q-tab-panel>
			<q-tab-panel :name="tabType.orderIds" class="q-px-none">
				<order-ids-filter
					@open-assign-dialog="openAssignDialog = true"
				/>
			</q-tab-panel>
		</q-tab-panels>
		<bulk-assignment-dialog
			v-if="openAssignDialog"
			@close="openAssignDialog = false"
		/>
		<batch-assignment-dialog
			v-if="openBatchAssignDialog"
			@close="openBatchAssignDialog = false"
		/>
		<shift-assignment-dialog
			v-if="openShiftAssignDialog"
			@close="openShiftAssignDialog = false"
		/>
	</div>
</template>

<style lang="scss">
// fix that jquery datepicker will be hidden by q-tabs
.dashboard-form-filter-tabs {
	& + .q-tab-panels.q-panel-parent {
		overflow: initial;

		> .q-panel.scroll {
			overflow: initial;
		}
	}
}
</style>
