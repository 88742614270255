<script>
const deliveryStageTypeMapping = {
	FIRST_MILE: { label: 'F', color: 'orange' },
	LAST_MILE: { label: 'L', color: 'orange' },
	FIRST_MILE_PICKUP: { label: 'PU', color: 'primary' },
	LAST_MILE_DISTRIBUTION: { label: 'DO', color: 'primary' },
	INSTANT_DELIVERY: { label: 'NOW', color: 'primary' },
	DIRECT_TO_CUSTOMER: { label: 'ToC', color: 'primary' }
}

export default {
	name: 'DeliveryStageTypeBadge',
	props: {
		value: {
			type: String,
			required: true
		}
	},
	methods: {
		getBadgeValue(val) {
			return deliveryStageTypeMapping[val]
		}
	}
}
</script>

<template>
	<span>
		<q-badge
			:label="getBadgeValue(value).label"
			:color="getBadgeValue(value).color"
		/>
		<q-tooltip>
			{{ value }}
		</q-tooltip>
	</span>
</template>
