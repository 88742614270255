<script>
import _ from 'lodash'

export default {
	name: 'ClientRefs',
	props: {
		deliveries: {
			type: Array,
			required: true
		},
		orderId: {
			type: Number,
			required: true
		}
	},
	data() {
		return {
			orderRef: '',
			parcelRefs: []
		}
	},
	created() {
		// this.groupedClientRefs = groupBy(this.clientRefs, 'type')
		this.orderRef = _.get(this.deliveries, '0.orderRef', '')
		this.parcelRefs = _.map(this.deliveries, 'parcelRef')
	}
}
</script>

<template>
	<div class="client-refs">
		<div v-if="orderRef">
			ORDER:
			<div>
				{{ orderRef }}
			</div>
		</div>
		<div v-if="parcelRefs.length > 0">
			PARCEL:
			<div
				v-for="parcelRef in parcelRefs"
				:key="orderId + parcelRef.value"
			>
				{{ parcelRef }}
			</div>
		</div>
		(id: {{ orderId }})
	</div>
</template>

<style scoped lang="scss">
.client-refs {
	display: flex;
	flex-direction: column;
	row-gap: 3px;
}
</style>
