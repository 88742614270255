var render = function render(){var _vm=this,_c=_vm._self._c;return _c('layout',{attrs:{"title":"Dashboard"}},[_c('form-filter'),_c('common-table',{attrs:{"selected-rows":_vm.selectedOrders,"columns":_vm.columns,"data":_vm.data,"total-page":_vm.tableTotalPage,"on-page-change":_vm.onPageChange,"current-page":_vm.tabFilter.filter.page,"current-sort":{
			sortBy: _vm.tabFilter.filter.sortBy,
			descending: _vm.tabFilter.filter.sortDirection === 'DESC'
		},"virtual-scroll":"","binary-state-sort":"","rows-per-page":_vm.rowsPerPage},on:{"update:selectedRows":function($event){_vm.selectedOrders=$event},"update:selected-rows":function($event){_vm.selectedOrders=$event},"selection":_vm.updateSelectedOrders},scopedSlots:_vm._u([{key:"top-left",fn:function(){return [_c('dashboard-summary')]},proxy:true},{key:"deliveryStageType",fn:function({ row }){return [_c('delivery-stage-type',{attrs:{"delivery-stage":row.deliveryStage,"extras":row.extras}})]}},{key:"clientRefs",fn:function({ row }){return [_c('client-refs',{attrs:{"order-id":row.id,"deliveries":row.deliveries}})]}},{key:"address",fn:function({ row }){return [_c('order-address',{attrs:{"addresses":row.address}})]}},{key:"route",fn:function({ row }){return [_c('route',{attrs:{"extras":row.extras}})]}},{key:"district",fn:function({ row }){return [_c('div',{staticClass:"district-container"},[_c('div',[_vm._v(_vm._s(row.pickupDistrict))]),_c('div',[_vm._v("⬇")]),_c('div',[_vm._v(_vm._s(row.deliveryDistrict))])])]}},{key:"distance",fn:function({ row }){return [_vm._v(" "+_vm._s(row.distance || 'Unknown')+" KM ")]}},{key:"remark",fn:function({ row }){return [_c('remark',{attrs:{"notes":row.notes || [],"extras":row.extras || {}}})]}},{key:"courier",fn:function({ row }){return [_c('order-request-user-search-dashboard',{attrs:{"order":row,"alias-types":_vm.userAliasTypes,"refresh-list":_vm.getDashboard}})]}},{key:"status",fn:function({ row }){return [_c('status',{attrs:{"order-status":row.status,"deliveries":row.deliveries}})]}},{key:"pickupAtSLA",fn:function({ row }){return [_c('pickup-at-sla',{attrs:{"pickup-at":row.pickupAt,"delivery-by":row.deliveryBy,"status":row.status,"is-cancelled":row.isCancelled}})]}},{key:"actions",fn:function({ row }){return [_c('router-link',{attrs:{"title":"details","to":{
					path: `orders/${row.id}`,
					query: !!row.deletedAt ? { isDeleted: true } : undefined
				}}},[_vm._v(" 🗒️ ")])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }