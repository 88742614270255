<script>
import _ from 'lodash'

export default {
	name: 'Remark',
	props: {
		notes: {
			type: Array,
			default: () => []
		},
		extras: {
			type: Array,
			default: () => []
		}
	},
	methods: {
		getExtraInfo: _.memoize(function (extras) {
			const extraList = []
			for (const extra of extras || []) {
				const pairs = _.toPairs(extra)
				for (const pair of pairs) {
					if (!_.isObject(pair[1])) {
						extraList.push(pair)
					}
				}
			}
			return extraList
		})
	},
	computed: {
		mappedNotes() {
			return this.notes
				? this.notes.flatMap(item =>
						_.isObject(item)
							? Object.entries(item).map(
									([key, val]) =>
										`${key}: ${_.castArray(val).join(', ')}`
							  )
							: [item]
				  )
				: []
		}
	}
}
</script>

<template>
	<div class="dashboard-remarks">
		<div v-for="note in mappedNotes.slice(0, 3)" :key="note">
			{{ note }}
		</div>
		<template v-if="mappedNotes && mappedNotes.length > 3">
			<div>
				<q-btn color="primary" dense flat>[Display more]</q-btn>
				<q-tooltip anchor="center right" self="center start">
					<div v-for="note in mappedNotes.slice(3)" :key="note">
						{{ note }}
					</div>
				</q-tooltip>
			</div>
		</template>
		<div
			v-if="
				mappedNotes &&
				mappedNotes.length > 0 &&
				extras &&
				extras.length > 0
			"
		>
			- - - - - - -
		</div>
		<div v-if="extras && extras.length > 0">Extras:</div>
		<div
			v-for="[key, value] in getExtraInfo(extras).slice(0, 3)"
			:key="key"
		>
			{{ key }}:{{ value }}
		</div>
		<template v-if="getExtraInfo(extras).length > 3">
			<q-btn color="primary" dense flat>
				[Display more]
				<q-tooltip anchor="center right" self="center start">
					<div
						v-for="[key, value] in getExtraInfo(extras).slice(3)"
						:key="key"
					>
						{{ key }}: {{ value }}
					</div>
				</q-tooltip>
			</q-btn>
		</template>
	</div>
</template>

<style lang="scss">
.dashboard-remarks {
	max-width: 200px;
	white-space: pre-line;
	word-break: break-word;
}
</style>
