<script>
import _ from 'lodash'
import logger from '@/lib/utils/log'
import { mapActions, mapGetters, mapState } from 'vuex'
import TooltipButton from '@/components/TooltipButton.vue'
import axiosApiClient from '@/api/axiosApiClient'

export default {
	name: 'BulkAssignmentsDialog',
	components: {
		TooltipButton
	},
	data() {
		return {
			drivers: [],
			aliasTypes: ['PHONE', 'EMAIL', 'NAME', 'ID'],
			currentDriver: null,
			currentAliasType: 'PHONE',
			contact: '+852',
			loading: false,
			selectedOptions: [],
			options: []
		}
	},
	computed: {
		...mapState('dashboard', ['selectedOrders']),
		...mapGetters('dashboard', ['tab'])
	},
	watch: {
		contact() {
			this.searchByAlias()
		},
		currentAliasType() {
			this.contact = ''
			this.drivers = []
		}
	},
	created() {
		const initialOptions = this.selectedOrders.map(order => {
			const clientRefs = _.groupBy(order.clientRefs, 'type')
			const orderRefLabel =
				_.map(_.get(clientRefs, 'ORDER', []), 'value').join(',') || '-'
			const parcelRefLabel =
				_.map(_.get(clientRefs, 'PARCEL', []), 'value').join(',') || '-'
			const shelfId = _.get(
				_.find(order.extras, extra => _.get(extra, 'shelfId')),
				'shelfId',
				'-'
			)
			const label = `[${order.id}] ORDER: ${orderRefLabel}, PARCEL: ${parcelRefLabel}, SHELFID: ${shelfId}`
			return { label, value: `${order.id}`, userIds: order.userIds || [] }
		})
		this.selectedOptions = _.map(initialOptions, 'value')
		this.options = initialOptions
	},
	methods: {
		...mapActions('dashboard', [
			'getDashboard',
			'patchOrderForCourier',
			'postBatchOrderAssign'
		]),
		searchByAlias: _.debounce(async function () {
			const isGetByUserNameOrUserId = ['NAME', 'ID'].includes(
				this.currentAliasType
			)
			const threshold = isGetByUserNameOrUserId ? 0 : 5
			if (this.contact.length <= threshold) {
				this.drivers = []
				return
			}
			try {
				const url = isGetByUserNameOrUserId
					? `/users?userInfoType=${this.currentAliasType}` +
					  `&userInfo=${encodeURIComponent(this.contact)}&role=COR`
					: `/users?userAliasType=${this.currentAliasType}` +
					  `&userAliasId=*${encodeURIComponent(this.contact)}` +
					  `*&role=COR`

				const { data: drivers } = await axiosApiClient({
					method: 'GET',
					url
				})
				const driverList = Array.isArray(drivers) ? drivers : [drivers]
				this.drivers = driverList.map(driver => {
					const { id } = driver
					const { name = '-' } = driver.info
					const contact =
						_.find(driver.aliases, alias => alias.id).id || '-'

					return { id, name, contact }
				})
			} catch (e) {
				logger.error('User search', { category: 'API' }, e.stack)
				this.$q.notify({
					message: e.message,
					type: 'negative',
					position: 'top'
				})
			}
		}, 1000),
		getAliasTypeTranslation(aliasType) {
			switch (aliasType) {
				case 'NAME':
					return 'DRIVER NAME'
				case 'ID':
					return 'DRIVER ID'
				default:
					return aliasType
			}
		},
		async confirm() {
			this.loading = true
			try {
				const selectedOrders = this.options.filter(option =>
					this.selectedOptions.includes(option.value)
				)
				if (
					process.env
						.VUE_APP_DEMAND_6960_BATCH_ASSIGN_ORDERS_API_CHANGES ===
					'true'
				) {
					const orderIds = _.map(selectedOrders, order =>
						parseInt(order.value)
					)
					await this.postBatchOrderAssign({
						orderIds,
						userId: this.currentDriver.id
					})
				} else {
					const tasks = []
					for (const { value, userIds } of selectedOrders) {
						const newUserIds = [
							...new Set([...userIds, this.currentDriver.id])
						]

						tasks.push(
							this.patchOrderForCourier({
								orderId: value,
								userIds: newUserIds
							})
						)
					}
					await Promise.all(tasks)
				}

				this.$q.notify({
					message: 'Order(s) assigned',
					type: 'positive',
					position: 'top'
				})
			} catch (e) {
				this.$q.notify({
					message: e.message,
					type: 'negative',
					position: 'top'
				})
			} finally {
				await this.getDashboard()
				this.loading = false
				this.$emit('close')
			}
		},
		formatDriverLabel(driver) {
			const driverName = _.get(driver, 'name', '-')
			const driverContact = _.get(driver, 'contact', '-')
			const driverId = _.get(driver, 'id', '-')
			return `${driverName} (${driverContact} / ${driverId})`
		}
	}
}
</script>

<template>
	<q-dialog value @before-hide="$emit('close')">
		<q-card class="dashboard-bulk-assignments-form">
			<q-card-section>
				<h2 class="no-margin">Assign Order(s)</h2>
			</q-card-section>
			<q-card-section class="content">
				<q-scroll-area
					style="flex: 0 1 60%"
					visible
					bar-style="background: #757575"
				>
					<q-option-group
						v-model="selectedOptions"
						:options="options"
						type="checkbox"
						class="order-group"
					/>
				</q-scroll-area>
				<div class="assignment-form">
					<h4 class="no-margin">
						Selected orders ({{ options.length }} in total) will be
						assigned to
					</h4>
					<div class="alias-types">
						<q-radio
							v-for="aliasType in aliasTypes"
							:key="aliasType"
							v-model="currentAliasType"
							:val="aliasType"
							:label="getAliasTypeTranslation(aliasType)"
						/>
					</div>
					<q-input
						v-model="contact"
						class="driver-alias"
						label="Please input driver alias"
						filled
						dense
						square
					/>
					<q-scroll-area style="flex: 1" visible>
						<div class="driver-list">
							<q-radio
								v-for="driver in drivers"
								:key="driver.id"
								v-model="currentDriver"
								:val="driver"
								:label="formatDriverLabel(driver)"
							/>
						</div>
					</q-scroll-area>
				</div>
			</q-card-section>
			<q-card-actions align="right">
				<q-btn
					label="Cancel"
					color="primary"
					flat
					no-caps
					@click="$emit('close')"
				/>
				<div class="q-ml-sm" />
				<tooltip-button
					:disable="!(currentDriver && selectedOptions.length !== 0)"
					label="Confirm"
					color="primary"
					:tooltips="['At least pick one order and one driver']"
					@click="confirm"
				/>
			</q-card-actions>
			<q-inner-loading :showing="loading">
				<q-spinner color="primary" size="3em" />
			</q-inner-loading>
		</q-card>
	</q-dialog>
</template>

<style lang="scss">
.q-card.dashboard-bulk-assignments-form {
	display: flex;
	flex-direction: column;
	width: 1280px;
	max-width: 1280px;
	min-height: 620px;

	.content {
		display: flex;
		column-gap: 30px;
		flex: 1;

		> div {
			flex: 1;
		}

		.order-group {
			margin-left: 0;

			> div {
				padding: 5px 5px 5px 20px;
				margin: 0 10px 0 0;

				&:not(:last-child) {
					border-bottom: 1px solid #ddd;
				}

				&:nth-child(even) {
					background: #eee;
				}
			}
		}

		.assignment-form {
			display: flex;
			flex-direction: column;
			row-gap: 10px;

			.alias-types {
				display: flex;

				.q-radio {
					flex: 1;
					justify-content: center;
					padding: 8px;
					border: 1px solid #ddd;

					&:not(:first-child) {
						border-left: 0;
					}

					.q-radio__inner {
						display: none;
					}

					&[aria-checked='true'] {
						background: #f16722;
						color: #fff;
					}
				}
			}

			.q-field .q-field__label {
				font-size: 11px;
			}

			.driver-list {
				display: flex;
				flex-direction: column;

				.q-radio {
					padding: 10px 20px;

					&:not(:last-child) {
						border-bottom: 1px solid #ddd;
					}

					&:nth-child(even) {
						background: #eee;
					}

					&[aria-checked='true'] {
						background: #f16722;
						color: #fff;
					}

					.q-radio__inner {
						display: none;
					}
				}
			}
		}
	}
}
</style>
