<script>
import { mapState } from 'vuex'
import AutoRefresh from './AutoRefresh.vue'

export default {
	name: 'Summary',
	components: { AutoRefresh },
	computed: {
		...mapState('dashboard', ['total'])
	}
}
</script>

<template>
	<div class="dashboard-summary">
		{{ total }} order(s) filtered,
		<auto-refresh />
	</div>
</template>

<style lang="scss" scoped>
.dashboard-summary {
	display: flex;
	align-items: center;
	grid-gap: 10px;
}
</style>
