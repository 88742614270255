<script>
import _ from 'lodash'
import DeliveryStageTypeBadge from './DeliveryStageTypeBadge.vue'
export default {
	name: 'DeliveryStageType',
	components: {
		DeliveryStageTypeBadge
	},
	props: {
		deliveryStage: {
			type: String,
			required: true
		},
		extras: {
			type: Array,
			required: true
		}
	},
	data() {
		return { deliveryType: null }
	},
	created() {
		this.deliveryType = _.get(this.extras, '0.deliveryType', '')
	}
}
</script>

<template>
	<div class="delivery-stage-type">
		<div>
			<delivery-stage-type-badge :value="deliveryStage" />
		</div>
		<div v-if="deliveryType">
			---
			<br />
			<delivery-stage-type-badge :value="deliveryType" />
		</div>
	</div>
</template>

<style scoped lang="scss">
.delivery-stage-type {
	display: flex;
	flex-direction: column;
	row-gap: 3px;
}
</style>
