<script>
/* global moment */
import Layout from '@/components/Layout.vue'
import CommonTable from '@/components/CommonTable.vue'
import FormFilter from './FormFilter/index.vue'
import ClientRefs from './components/ClientRefs.vue'
import OrderAddress from './components/OrderAddress.vue'
import Remark from './components/Remark.vue'
import Summary from './components/Summary.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import Route from './components/Route.vue'
import Status from './components/Status.vue'
import DeliveryStageType from './components/DeliveryStageType.vue'
import PickupAtSLA from './components/PickupAtSLA.vue'
import OrderRequestUserSearchDashboard from '@/components/OrderRequestUserSearchDashboard.vue'
import { tabType } from '@/store/modules/dashboard/config'
import { format } from '@/lib/plugins/date'

export default {
	name: 'Dashboard',
	components: {
		ClientRefs,
		Layout,
		CommonTable,
		OrderAddress,
		FormFilter,
		Remark,
		PickupAtSla: PickupAtSLA,
		DashboardSummary: Summary,
		Route,
		Status,
		DeliveryStageType,
		OrderRequestUserSearchDashboard
	},
	computed: {
		...mapState('dashboard', [
			'data',
			'total',
			'totalPage',
			'tabFilter',
			'selectedOrders',
			'userAliasTypes'
		]),
		...mapGetters('dashboard', ['tab']),
		columns() {
			return [
				{
					name: 'deliveryStageType',
					field: 'deliveryStageType',
					align: 'left'
				},
				{
					name: 'clientRefs',
					label: 'Client refs',
					field: 'clientRefs',
					align: 'left',
					sortable: this.tab === 'GENERAL'
				},
				{
					name: 'clientId',
					label: 'Client',
					field: 'clientId',
					align: 'left'
				},
				{
					name: 'address',
					label: 'Address & Contacts',
					field: 'address',
					align: 'left'
				},
				{
					name: 'route',
					label: 'Route',
					field: 'route',
					align: 'left'
				},
				{
					name: 'district',
					label: 'District',
					field: 'district',
					align: 'left'
				},
				{
					name: 'distance',
					label: 'Distance',
					field: 'distance',
					align: 'left'
				},
				{
					name: 'remark',
					label: 'Remark',
					field: 'remark',
					align: 'left'
				},
				{
					name: 'courier',
					label: 'Couriers',
					field: 'courier',
					align: 'left'
				},
				{
					name: 'status',
					label: 'Status',
					field: 'status',
					align: 'left'
				},
				{
					name: 'pickupAtSLA',
					label: `Pickup at/SLA (${format(moment(), null, 'UTCZ')})`,
					field: 'pickupAtSLA',
					align: 'left',
					sortable: this.tab === 'GENERAL'
				},
				{
					name: 'actions',
					label: 'Actions',
					align: 'center'
				}
			]
		},
		rowsPerPage() {
			return tabType.orderIds === this.tab ? this.total : 100
		},
		tableTotalPage() {
			return tabType.orderIds === this.tab ? 1 : this.totalPage
		}
	},
	created() {
		this.getUserAliasTypes()
	},
	methods: {
		...mapActions('dashboard', [
			'getDashboard',
			'updateSelectedOrders',
			'getUserAliasTypes'
		]),
		onPageChange(pagination) {
			const { page, limit, sortBy, descending } = pagination
			return this.getDashboard({
				page,
				limit,
				sortBy: sortBy === 'pickupAtSLA' ? 'pickupAt' : 'id',
				sortDirection: sortBy && !descending ? 'ASC' : 'DESC'
			})
		}
	}
}
</script>

<template>
	<layout title="Dashboard">
		<form-filter />
		<common-table
			:selected-rows.sync="selectedOrders"
			:columns="columns"
			:data="data"
			:total-page="tableTotalPage"
			:on-page-change="onPageChange"
			:current-page="tabFilter.filter.page"
			:current-sort="{
				sortBy: tabFilter.filter.sortBy,
				descending: tabFilter.filter.sortDirection === 'DESC'
			}"
			virtual-scroll
			binary-state-sort
			:rows-per-page="rowsPerPage"
			@selection="updateSelectedOrders"
		>
			<template #top-left>
				<dashboard-summary />
			</template>
			<template #deliveryStageType="{ row }">
				<delivery-stage-type
					:delivery-stage="row.deliveryStage"
					:extras="row.extras"
				/>
			</template>
			<template #clientRefs="{ row }">
				<client-refs :order-id="row.id" :deliveries="row.deliveries" />
			</template>
			<template #address="{ row }">
				<order-address :addresses="row.address" />
			</template>
			<template #route="{ row }">
				<route :extras="row.extras" />
			</template>
			<template #district="{ row }">
				<div class="district-container">
					<div>{{ row.pickupDistrict }}</div>
					<div>⬇</div>
					<div>{{ row.deliveryDistrict }}</div>
				</div>
			</template>
			<template #distance="{ row }">
				{{ row.distance || 'Unknown' }} KM
			</template>
			<template #remark="{ row }">
				<remark :notes="row.notes || []" :extras="row.extras || {}" />
			</template>
			<template #courier="{ row }">
				<order-request-user-search-dashboard
					:order="row"
					:alias-types="userAliasTypes"
					:refresh-list="getDashboard"
				/>
			</template>
			<template #status="{ row }">
				<status
					:order-status="row.status"
					:deliveries="row.deliveries"
				/>
			</template>
			<template #pickupAtSLA="{ row }">
				<pickup-at-sla
					:pickup-at="row.pickupAt"
					:delivery-by="row.deliveryBy"
					:status="row.status"
					:is-cancelled="row.isCancelled"
				/>
			</template>
			<template #actions="{ row }">
				<router-link
					title="details"
					:to="{
						path: `orders/${row.id}`,
						query: !!row.deletedAt ? { isDeleted: true } : undefined
					}"
				>
					🗒️
				</router-link>
			</template>
		</common-table>
	</layout>
</template>

<style lang="scss">
.district-container {
	max-width: 150px;
	white-space: pre-line;
}
</style>
