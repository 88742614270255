import { render, staticRenderFns } from "./ShiftAssignmentDialog.vue?vue&type=template&id=563dcd1c&"
import script from "./ShiftAssignmentDialog.vue?vue&type=script&lang=js&"
export * from "./ShiftAssignmentDialog.vue?vue&type=script&lang=js&"
import style0 from "./ShiftAssignmentDialog.vue?vue&type=style&index=0&id=563dcd1c&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QOptionGroup from 'quasar/src/components/option-group/QOptionGroup.js';
import QRadio from 'quasar/src/components/radio/QRadio.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import QField from 'quasar/src/components/field/QField.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QDialog,QCard,QCardSection,QScrollArea,QCheckbox,QOptionGroup,QRadio,QItemSection,QItemLabel,QCardActions,QBtn,QInnerLoading,QSpinner,QField});
