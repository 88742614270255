<script>
import { mapActions } from 'vuex'

const defaultAutoRefreshTimeout = 180

export default {
	data() {
		return {
			autoRefreshOn: true,
			timeoutSecond: defaultAutoRefreshTimeout,
			intervalId: null
		}
	},
	computed: {
		remainingTime() {
			const minutes = Math.floor(this.timeoutSecond / 60)
			const seconds = this.timeoutSecond % 60
			return `0${minutes}:${seconds >= 10 ? seconds : '0' + seconds}`
		}
	},
	watch: {
		autoRefreshOn(onAutoRefresh) {
			if (onAutoRefresh) {
				this.setAutoRefreshInterval()
			} else {
				this.clearAutoRefreshInterval()
			}
		},
		timeoutSecond(second) {
			if (second === 0) {
				this.getDashboard()
				this.clearAutoRefreshInterval()
				this.setAutoRefreshInterval()
			}
		}
	},
	mounted() {
		this.setAutoRefreshInterval()
	},
	methods: {
		...mapActions('dashboard', ['getDashboard']),
		clearAutoRefreshInterval() {
			clearInterval(this.intervalId)
			this.intervalId = null
		},
		setAutoRefreshInterval() {
			this.timeoutSecond = defaultAutoRefreshTimeout
			this.intervalId = setInterval(() => this.timeoutSecond--, 1000)
		}
	},
	beforeUnmount() {
		this.clearAutoRefreshInterval()
	}
}
</script>

<template>
	<div class="dashboard-auto-refresh">
		<span v-if="autoRefreshOn">[Refresh in {{ remainingTime }}]</span>
		<span v-else>Auto-Refresh</span>
		<q-checkbox v-model="autoRefreshOn" />
	</div>
</template>

<style scoped lang="scss">
.dashboard-auto-refresh {
	display: flex;
	align-items: center;

	&::v-deep .q-checkbox {
		.q-checkbox__inner {
			font-size: 30px;
		}
	}
}
</style>
