<script>
import _ from 'lodash'

export default {
	name: 'Status',
	props: {
		orderStatus: {
			type: String,
			required: true
		},
		deliveries: {
			type: Array,
			required: true,
			default: () => []
		}
	},
	computed: {
		deliveryStatus() {
			return _.get(this.deliveries, '0.status', 'N/A')
		}
	}
}
</script>

<template>
	<div class="status">
		{{ orderStatus }}
		<br />
		({{ deliveryStatus }})
	</div>
</template>

<style scoped lang="scss">
.status {
	display: flex;
	flex-direction: column;
	justify-content: center;
	row-gap: 3px;
	height: 100%;
	max-width: 300px;

	> div {
		> div {
			white-space: pre-line;
		}
	}
}
</style>
