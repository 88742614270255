<script>
import _ from 'lodash'
import moment from 'moment-timezone'
import { mapActions, mapGetters, mapState } from 'vuex'
import DateInput from '@/components/DateInput.vue'
import FormInput from '@/components/FormInput.vue'
import FormSelect from '@/components/FormSelect.vue'
import CommonForm from '@/components/CommonForm.vue'
import TooltipButton from '@/components/TooltipButton.vue'

export default {
	name: 'General',
	components: {
		DateInput,
		FormInput,
		FormSelect,
		CommonForm,
		TooltipButton
	},
	data() {
		return {
			filter: {
				deliveryType: null,
				deliveryStage: null,
				deliveryStatus: null,
				minPickupAt: null,
				maxPickupAt: null,
				maxDeliveryBy: null,
				pickupDistrict: null,
				deliveryDistrict: null,
				locationId: null,
				clientId: null
			},
			deliveryStageOptions: [
				{ label: '(F) FIRST_MILE', value: 'FIRST_MILE' },
				{ label: '(L) LAST_MILE', value: 'LAST_MILE' }
			],
			deliveryTypeOptions: [
				{ label: '(PU) FIRST_MILE_PICKUP', value: 'FIRST_MILE_PICKUP' },
				{
					label: '(DO) LAST_MILE_DISTRIBUTION',
					value: 'LAST_MILE_DISTRIBUTION'
				},
				{ label: '(NOW) INSTANT_DELIVERY', value: 'INSTANT_DELIVERY' },
				{
					label: '(ToC) DIRECT_TO_CUSTOMER',
					value: 'DIRECT_TO_CUSTOMER'
				}
			],
			deliveryStatusOptions: [
				'AWAITS_PICKUP',
				'AWAITS_DRIVER',
				'FAILED_PICKUP',
				'FAILED',
				'DELIVERING',
				'RESCHEDULED',
				'RETURNED',
				'CANCELED',
				'DELIVERED'
			],
			// TODO: use button-dropdown?: https://v1.quasar.dev/vue-components/button-dropdown
			locale: null
		}
	},
	computed: {
		...mapState('dashboard', [
			'selectedOrders',
			'clientList',
			'localeList',
			'total'
		]),
		...mapGetters('dashboard', ['liftedFilter', 'locationIdList']),
		disabledExport() {
			const { locationId, minPickupAt, maxPickupAt, maxDeliveryBy } =
				this.liftedFilter

			const isPickupTimeGapWithinOneMonth =
				moment
					.utc(maxPickupAt)
					.diff(moment.utc(minPickupAt), 'months') < 1

			return !_.every(
				[
					locationId,
					minPickupAt,
					maxPickupAt,
					maxDeliveryBy,
					this.locale,
					isPickupTimeGapWithinOneMonth
					// prettier-preserve
				],
				Boolean
			)
		},
		disabledBatch() {
			const { locationId, minPickupAt, maxPickupAt, deliveryStatus } =
				this.liftedFilter
			return !_.every(
				[
					locationId,
					minPickupAt,
					maxPickupAt,
					deliveryStatus == 'AWAITS_DRIVER',
					this.total > 0
				],
				Boolean
			)
		}
	},
	async created() {
		if (this.clientList.length === 0) {
			await this.getClientIdList()
		}
		if (this.locationIdList.length === 0) {
			await this.getLocationList()
		}
		if (this.localeList.length === 0) {
			await this.getLocaleIdList()
		}
		this.syncFilter()
	},
	methods: {
		...mapActions('dashboard', [
			'getDashboardByGeneralFilter',
			'resetGeneralFilter',
			'exportOrders',
			'getClientIdList',
			'getLocationList',
			'getLocaleIdList'
		]),
		async reset() {
			await this.resetGeneralFilter()
			this.syncFilter()
			await this.getDashboardByGeneralFilter(this.filter)
		},
		submit() {
			return this.getDashboardByGeneralFilter(this.filter)
		},
		syncFilter() {
			this.filter = { ...this.liftedFilter }
		},
		exportOrdersData() {
			this.exportOrders(this.locale)
		}
	}
}
</script>

<template>
	<common-form :reset="reset" :submit="submit" layout="vertical">
		<div class="dashboard-general-filter">
			<div>
				<form-select
					v-model="filter.deliveryStage"
					:options="deliveryStageOptions"
					label="Delivery Stage"
					nullable
				/>
				<form-select
					v-model="filter.deliveryType"
					:options="deliveryTypeOptions"
					label="Delivery Type"
					style="width: 224px"
					nullable
				/>
				<form-select
					v-model="filter.deliveryStatus"
					:options="deliveryStatusOptions"
					label="Delivery Status"
					nullable
				/>
				<date-input
					v-model="filter.minPickupAt"
					label="Pick-Up (MIN)"
					jquery
				/>
				<date-input
					v-model="filter.maxPickupAt"
					label="Pick-Up (MAX)"
					jquery
				/>
				<date-input v-model="filter.maxDeliveryBy" label="SLA" jquery />
			</div>
			<div>
				<form-input
					v-model="filter.pickupDistrict"
					label="District (Pick-Up)"
				/>
				<form-input
					v-model="filter.deliveryDistrict"
					label="District (Drop-off)"
				/>
				<form-select
					v-model="filter.locationId"
					:options="locationIdList"
					label="Market"
					nullable
				/>
				<form-select
					v-model="filter.clientId"
					:options="clientList"
					label="Client"
					nullable
				/>
				<form-select
					v-model="locale"
					:options="localeList"
					label="Locale"
					nullable
				/>
			</div>
		</div>
		<template #extra-buttons>
			<div class="q-ml-xl">Action:</div>
			<tooltip-button
				:tooltips="['At least one order be selected']"
				:disable="selectedOrders.length === 0"
				color="primary"
				label="Assign order(s)"
				@click="$emit('open-assign-dialog')"
			/>
			<tooltip-button
				:tooltips="[
					'Required fields to export filtered orders:',
					'1. Market',
					'2. Pick-Up (MIN)',
					'3. Pick-Up (MAX)',
					'4. Delivery Status = AWAITS_DRIVER',
					'5. Number of Orders > 0'
				]"
				:disable="disabledBatch"
				color="primary"
				label="Assign order(s) by batch"
				@click="$emit('open-batch-assign-dialog')"
			/>
			<q-btn
				color="primary"
				label="Assign order(s) by shift"
				@click="$emit('open-shift-assign-dialog')"
				no-caps
			/>
			<tooltip-button
				:tooltips="[
					'Required fields to export filtered orders:',
					'1. Market',
					'2. SLA',
					'3. Pick-Up (MIN)',
					'4. Pick-Up (MAX)',
					'5. Locale',
					'----------',
					'Reminder: Time gap between Input 3 and Input 4 cannot exceed one month'
				]"
				:disable="disabledExport"
				color="primary"
				label="Export result"
				@click="exportOrdersData"
			/>
		</template>
	</common-form>
</template>

<style lang="scss">
.dashboard-general-filter {
	display: flex;
	flex-direction: column;
	grid-gap: 10px;

	> div {
		display: flex;
		flex-wrap: wrap;
		grid-gap: 10px;
	}
}
</style>
