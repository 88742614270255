<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import FormInput from '@/components/FormInput.vue'
import CommonForm from '@/components/CommonForm.vue'
import TooltipButton from '@/components/TooltipButton.vue'
import _ from 'lodash'

export default {
	name: 'OrderIds',
	components: {
		FormInput,
		CommonForm,
		TooltipButton
	},
	data() {
		return {
			filter: {
				ids: null
			}
		}
	},
	computed: {
		...mapState('dashboard', ['selectedOrders']),
		...mapGetters('dashboard', ['liftedFilter'])
	},
	created() {
		this.syncFilter()
	},
	methods: {
		...mapActions('dashboard', [
			'getDashboardByOrderIdsFilter',
			'resetOrderIdsFilter'
		]),
		async reset() {
			await this.resetOrderIdsFilter()
			this.syncFilter()
			await this.getDashboardByOrderIdsFilter(this.filter)
		},
		submit() {
			return this.getDashboardByOrderIdsFilter(this.filter)
		},
		syncFilter() {
			this.filter = { ..._.pick(this.liftedFilter, ['ids']) }
		}
	}
}
</script>

<template>
	<common-form :reset="reset" :submit="submit" layout="vertical">
		<form-input
			v-model="filter.ids"
			label="Order id list"
			type="textarea"
			input-style="height:50px;width:150px;"
		/>
		<template #extra-buttons>
			<div class="q-ml-xl">Action:</div>
			<tooltip-button
				:tooltips="['At least one order be selected']"
				:disable="selectedOrders.length === 0"
				color="primary"
				label="Assign order(s)"
				@click="$emit('open-assign-dialog')"
			/>
		</template>
	</common-form>
</template>
