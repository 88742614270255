<script>
import _ from 'lodash'
import logger from '@/lib/utils/log'
import { mapActions, mapGetters, mapState } from 'vuex'
import CommonForm from '@/components/CommonForm.vue'
import TooltipButton from '@/components/TooltipButton.vue'
import FormInput from '@/components/FormInput.vue'
import FormSelect from '@/components/FormSelect.vue'
import DateInput from '@/components/DateInput.vue'
import moment from 'moment-timezone'
import { getShiftRegistrations } from '@/api/shifts-api'

export default {
	name: 'ShiftAssignmentsDialog',
	components: {
		TooltipButton,
		CommonForm,
		FormSelect,
		FormInput,
		DateInput
	},
	data() {
		return {
			currentDriver: null,
			loading: false,
			selectedOptions: [],
			options: null,
			selectedLocation:
				(!_.isNil(
					localStorage.getItem('defaultShiftOrderAssignLocationId')
				) &&
					JSON.parse(
						localStorage.getItem(
							'defaultShiftOrderAssignLocationId'
						)
					)) ||
				null,
			selectedDate: moment().format('YYYY-MM-DD'),
			shiftList: [],
			shiftRegistrationFullList: [],
			targetShiftRegistrations: [],
			checkAllOptionsOn: false,
			shiftTimeRanges: [],
			filter: {
				selectedShiftTimeRange: null,
				pickupDistrict: null,
				deliveryDistrict: null,
				clientId: null
			}
		}
	},
	computed: {
		...mapState('dashboard', [
			'selectedOrders',
			'shiftDialogOrderList',
			'locationList',
			'clientList'
		]),
		...mapGetters('dashboard', ['tab', 'locationIdList']),
		isSelectedShiftTimeRange() {
			return !_.isNil(this.filter.selectedShiftTimeRange)
		},
		locationIdSelectOptions() {
			return _.map(this.locationList, location => ({
				label: location.id,
				value: location
			}))
		}
	},
	watch: {
		selectedLocation() {
			localStorage.setItem(
				'defaultShiftOrderAssignLocationId',
				JSON.stringify(this.selectedLocation)
			)
			this.getShiftRegistrations()
		},
		selectedDate() {
			this.getShiftRegistrations()
		},
		selectedShiftTimeRange() {
			this.getOrders()
		},
		selectedOptions(newSelectedOptions) {
			// if selectedOptions equals options length && options length is greater than 0
			// set to be checked
			// otherwise set to be null, hence checkbox having a "-" sign
			if (
				this.options &&
				this.options.length > 0 &&
				newSelectedOptions.length === this.options.length
			) {
				this.checkAllOptionsOn = true
			} else if (newSelectedOptions.length === 0) {
				this.checkAllOptionsOn = false
			} else {
				this.checkAllOptionsOn = null
			}
		},
		checkAllOptionsOn(checked) {
			// if checkbox is true then we loop through options and push all value into selectedOptions,
			// else if checked is false we set to empty []
			if (checked) {
				const allSelectedOptions = this.options.map(({ value }) => {
					return value
				})
				this.selectedOptions = [...allSelectedOptions]
			} else if (checked === false) {
				this.selectedOptions = []
			}
		}
	},
	async created() {
		this.getShiftRegistrations()
		this.selectedLocation = this.getStoredLocation()
		localStorage.setItem(
			'defaultShiftOrderAssignLocationId',
			JSON.stringify(this.selectedLocation)
		)
		if (this.clientList.length === 0) {
			await this.getClientIdList()
		}
	},
	methods: {
		...mapActions('dashboard', [
			'getDashboard',
			'patchOrderForCourier',
			'postBatchOrderAssign',
			'getDashboardOrdersByShift',
			'getLocationList',
			'getClientIdList'
		]),
		async confirm() {
			this.loading = true
			try {
				const selectedOrders = this.options.filter(option =>
					this.selectedOptions.includes(option.value)
				)
				if (
					process.env
						.VUE_APP_DEMAND_6960_BATCH_ASSIGN_ORDERS_API_CHANGES ===
					'true'
				) {
					const orderIds = _.map(selectedOrders, order =>
						parseInt(order.value)
					)
					await this.postBatchOrderAssign({
						orderIds,
						userId: this.currentDriver.id
					})
				} else {
					const tasks = []
					for (const { value, userIds } of selectedOrders) {
						const newUserIds = [
							...new Set([...userIds, this.currentDriver.id])
						]
						tasks.push(
							this.patchOrderForCourier({
								orderId: value,
								userIds: newUserIds
							})
						)
					}
					await Promise.all(tasks)
				}

				this.$q.notify({
					message: 'Order(s) assigned',
					type: 'positive',
					position: 'top'
				})
			} catch (e) {
				this.$q.notify({
					message: e.message,
					type: 'negative',
					position: 'top'
				})
			} finally {
				await this.getOrders()
				this.currentDriver = null
				this.selectedOptions = []
				this.loading = false
			}
		},
		getStoredLocation() {
			if (
				!_.isNil(
					localStorage.getItem('defaultShiftOrderAssignLocationId')
				)
			) {
				const storedLocation = JSON.parse(
					localStorage.getItem('defaultShiftOrderAssignLocationId')
				)
				const location = _.find(this.locationList, {
					id: storedLocation.id
				})
				return location
			} else {
				return null
			}
		},
		resetShiftDialog() {
			this.options = null
			this.shiftList = null
			this.shiftRegistrationFullList = null
			this.filter.selectedShiftTimeRange = null
			this.filter.pickupDistrict = null
			this.filter.deliveryDistrict = null
			this.filter.clientId = null
			this.selectedOptions = []
			this.shiftTimeRanges = []
			this.drivers = null
			this.currentDriver = null
			this.selectedLocation
				? localStorage.setItem(
						'defaultShiftOrderAssignLocationId',
						JSON.stringify(this.selectedLocation)
				  )
				: localStorage.removeItem('defaultShiftOrderAssignLocationId')
		},
		async getLocationIdShiftsByDate({ location, startAt, endAt }) {
			try {
				const data = await getShiftRegistrations({
					locationId: location,
					shiftStartAt: startAt,
					shiftEndAt: endAt,
					status: 'APPROVED',
					limit: 500
				})
				return data
			} catch (e) {
				logger.error(
					'Shift Registration search',
					{ category: 'API' },
					e.stack
				)
				this.$q.notify({
					message: e.message,
					type: 'negative',
					position: 'top'
				})
			}
		},
		async getShiftRegistrations() {
			this.resetShiftDialog()
			if (_.isEmpty(this.locationList)) {
				await this.getLocationList()
			}
			if (this.selectedLocation && this.selectedDate) {
				const dateStartAt = moment
					.tz(
						`${this.selectedDate} 00:00:00`,
						this.selectedLocation.timezone
					)
					.utc()
					.format()
				const dateEndAt = moment
					.tz(
						`${this.selectedDate} 23:59:59`,
						this.selectedLocation.timezone
					)
					.utc()
					.format()
				const { data } = await this.getLocationIdShiftsByDate({
					location: this.selectedLocation.id,
					startAt: dateStartAt,
					endAt: dateEndAt
				})
				const shiftList = _.uniqWith(
					_.map(_.castArray(data), 'shift'),
					_.isEqual
				)
				const timeRanges = _.map(shiftList, (shift, i) => {
					const startAt = moment
						.utc(shift.shiftStartAt)
						.tz(this.selectedLocation.timezone)
					const endAt = moment
						.utc(shift.shiftEndAt)
						.tz(this.selectedLocation.timezone)
					return {
						label: `${startAt.format('HH:mm')} - ${endAt
							.add(1, 'second')
							.format('HH:mm')}`,
						value: i
					}
				})
				this.shiftRegistrationFullList = data
				this.shiftList = shiftList
				this.shiftTimeRanges = timeRanges
			} else {
				this.shiftList = []
			}
		},
		async getOrders(filter) {
			this.targetShiftRegistrations = null
			if (!_.isNil(this.filter.selectedShiftTimeRange)) {
				const targetShift = _.get(
					this.shiftList,
					this.filter.selectedShiftTimeRange
				)
				this.targetShiftRegistrations = _.filter(
					this.shiftRegistrationFullList,
					['shift.id', targetShift.id]
				)
				await this.getDashboardOrdersByShift({
					...filter,
					startAt: targetShift.shiftStartAt,
					endAt: targetShift.shiftEndAt,
					locationId: targetShift.locationId
				})
				const initialOptions = this.shiftDialogOrderList.map(order => {
					const orderRefLabel =
						_.get(order, 'deliveries.0.orderRef') || '-'
					const parcelRefLabel =
						_.map(_.get(order, 'deliveries', []), 'parcelRef').join(
							','
						) || '-'
					const pickupDistrictLabel =
						_.get(order, 'pickupDistrict') || '-'
					const deliveryDistrictLabel =
						_.get(order, 'deliveryDistrict') || '-'

					// if orderRefLabel and parcelRefLabel are the same then will combine together
					const refLabel =
						orderRefLabel === parcelRefLabel
							? `ORDER & PARCEL: ${orderRefLabel}`
							: `ORDER: ${orderRefLabel}, PARCEL: ${parcelRefLabel}`
					const label = `[${order.id}] ${refLabel}, PICK-UP: ${pickupDistrictLabel}, DROP-OFF: ${deliveryDistrictLabel}`
					return {
						label,
						value: `${order.id}`,
						userIds: order.userIds || []
					}
				})
				this.options = initialOptions
			}
		},
		formatDriverLabel(driver) {
			const driverName = _.get(driver, 'info.name', '-')
			const driverAlias = _.get(driver, 'aliases', [])
			const driverContact = _.find(driverAlias, { type: 'PHONE' })
			const driverId = _.get(driver, 'id', '-')
			return `${driverName} (${_.get(
				driverContact,
				'value',
				'-'
			)} / ${driverId})`
		},
		formatDriverDistrictLabel(registration) {
			return !_.isEmpty(registration.districts)
				? registration.districts.toString()
				: 'No Districts Selected'
		},
		// TODO: in future investigate why SelectedShiftTimeRange filter is not responding without using this method
		onSelectedShiftTimeRangeChange(value) {
			this.filter = {
				...this.filter,
				selectedShiftTimeRange: value
			}
		},
		async reset() {
			this.resetShiftDialog()
			this.selectedDate = moment().format('YYYY-MM-DD')
			this.getShiftRegistrations()
		},
		submit() {
			// resets the selected options and current driver so that users wont be able to click submit button from previous filter
			this.currentDriver = null
			this.selectedOptions = []
			return this.getOrders(this.filter)
		}
	}
}
</script>

<template>
	<q-dialog value @before-hide="$emit('close')">
		<q-card class="dashboard-shift-assignments-form">
			<q-card-section>
				<h2 class="no-margin">Assign Order(s) by Shift</h2>
				<div class="form">
					<common-form
						class="form"
						:reset="reset"
						:submit="submit"
						layout="vertical"
						:disable-btn="
							shiftTimeRanges.length === 0 ||
							filter.selectedShiftTimeRange === null
						"
					>
						<form-select
							v-model="selectedLocation"
							:options="locationIdSelectOptions"
							label="LocationId*"
							nullable
						/>
						<date-input
							v-model="selectedDate"
							label="Date (Location Timezone)*"
							jquery
							date-only
							:picker-format="'YYYY-MM-DD'"
							:mask-format="'YYYY-MM-DD'"
						/>
						<form-select
							v-model="filter.selectedShiftTimeRange"
							@input="onSelectedShiftTimeRangeChange"
							:options="shiftTimeRanges"
							:disable="shiftTimeRanges.length === 0"
							label="Shift* (Location Timezone)"
							nullable
						/>
						<form-input
							v-model="filter.pickupDistrict"
							label="District (Pick-Up)"
							:disable="shiftTimeRanges.length === 0"
						/>
						<form-input
							v-model="filter.deliveryDistrict"
							label="District (Drop-off)"
							:disable="shiftTimeRanges.length === 0"
						/>
						<form-select
							v-model="filter.clientId"
							:options="clientList"
							:disable="shiftTimeRanges.length === 0"
							label="Client"
							nullable
						/>
					</common-form>
				</div>
			</q-card-section>
			<q-card-section class="content">
				<q-scroll-area
					style="flex: 0 1 60%"
					visible
					bar-style="background: #757575"
				>
					<div class="notice" v-if="options === null">
						<ol>
							<li>Select LocationId</li>
							<li>Select Date (Default Today)</li>
							<li>
								Select Shift (Will display Shifts which have
								Approved Shift Registration)
							</li>
							<li>
								Select districts or clients to further filter
								out the orders (optional)
							</li>
						</ol>
					</div>
					<div
						class="notice"
						v-else-if="options && options.length === 0"
					>
						No Orders can pickup or not assign in this Shift
					</div>
					<div
						v-if="options && options.length !== 0"
						class="check-all-checkbox"
					>
						<q-checkbox
							v-model="checkAllOptionsOn"
							type="checkbox"
							size="md"
						/>
					</div>
					<q-option-group
						v-if="options && options.length > 0"
						v-model="selectedOptions"
						:options="options"
						type="checkbox"
						class="order-group"
					/>
				</q-scroll-area>
				<div
					class="assignment-form"
					v-show="options && options.length > 0"
				>
					<h4 class="no-margin">
						Selected orders ({{ selectedOptions.length }} in total)
						will be assigned to
					</h4>
					<q-scroll-area style="flex: 1" visible>
						<div class="driver-list">
							<q-radio
								v-for="registration in targetShiftRegistrations"
								:key="registration.user.id"
								v-model="currentDriver"
								:val="registration.user"
							>
								<q-item-section>
									<q-item-label>
										{{
											formatDriverLabel(registration.user)
										}}
									</q-item-label>
									<q-item-label>
										{{
											formatDriverDistrictLabel(
												registration
											)
										}}
									</q-item-label>
								</q-item-section>
							</q-radio>
						</div>
					</q-scroll-area>
				</div>
			</q-card-section>
			<q-card-actions align="right">
				<q-btn
					label="Cancel"
					color="primary"
					flat
					no-caps
					@click="$emit('close')"
				/>
				<div class="q-ml-sm" />
				<tooltip-button
					:disable="!(currentDriver && selectedOptions.length !== 0)"
					label="Confirm"
					color="primary"
					:tooltips="['At least pick one order and one driver']"
					@click="confirm"
				/>
			</q-card-actions>
			<q-inner-loading :showing="loading">
				<q-spinner color="primary" size="3em" />
			</q-inner-loading>
		</q-card>
	</q-dialog>
</template>

<style lang="scss">
.q-card.dashboard-shift-assignments-form {
	display: flex;
	flex-direction: column;
	width: 1280px;
	max-width: 1280px;
	min-height: 620px;
	.notice {
		font-weight: bold;
	}
	.form {
		display: flex;
		flex-wrap: wrap;
		grid-gap: 10px;
		column-gap: 10px;
		flex-direction: row;
		> label:not(:first-child) {
			width: 180px;
		}
		.row {
			margin: 0;
			.q-field__inner {
				background: #fff;
				padding: 0 5px;
				border: 1px solid #eee;
				border-radius: 5px;
				align-self: auto;
			}
		}
	}

	.content {
		display: flex;
		column-gap: 30px;
		flex: 1;

		> div {
			flex: 1;
		}

		.check-all-checkbox {
			margin: 0 15px;
		}

		.order-group {
			margin-left: 0;

			> div {
				padding: 5px 5px 5px 15px;
				margin: 0 10px 0 0;

				&:not(:last-child) {
					border-bottom: 1px solid #ddd;
				}

				&:nth-child(even) {
					background: #eee;
				}
			}
		}

		.assignment-form {
			display: flex;
			flex-direction: column;
			row-gap: 10px;

			.alias-types {
				display: flex;

				.q-radio {
					flex: 1;
					justify-content: center;
					padding: 8px;
					border: 1px solid #ddd;

					&:not(:first-child) {
						border-left: 0;
					}

					.q-radio__inner {
						display: none;
					}

					&[aria-checked='true'] {
						background: #f16722;
						color: #fff;
					}
				}
			}

			.q-field .q-field__label {
				font-size: 11px;
			}

			.driver-list {
				display: flex;
				flex-direction: column;

				.q-radio {
					padding: 10px 20px;

					&:not(:last-child) {
						border-bottom: 1px solid #ddd;
					}

					&:nth-child(even) {
						background: #eee;
					}

					&[aria-checked='true'] {
						background: #f16722;
						color: #fff;
					}

					.q-radio__inner {
						display: none;
					}
				}
			}
		}
	}
}
</style>
