<script>
import TooltipButton from '@/components/TooltipButton.vue'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment-timezone'
import FormSelect from '@/components/FormSelect.vue'
import FormInput from '@/components/FormInput.vue'
import CommonForm from '@/components/CommonForm.vue'
import { toDate } from '@/lib/plugins/date'
import _ from 'lodash'
import logger from '@/lib/utils/log'

export default {
	name: 'BulkAssignmentsDialog',
	components: {
		TooltipButton,
		FormSelect,
		CommonForm,
		FormInput
	},
	data() {
		return {
			filter: { location: null, numOfCouriers: null },
			drivers: [],
			aliasTypes: ['PHONE', 'EMAIL', 'NAME', 'ID'],
			currentDriver: null,
			currentAliasType: 'PHONE',
			contact: '+852',
			selectedOrderBatch: [],
			orderBatches: [],
			orderBatchOptions: [],
			loading: false,
			orderCount: 0
		}
	},
	computed: {
		...mapGetters('dashboard', ['liftedFilter']),
		pickUpLocationList() {
			return this.orderBatchOptions.map(({ address, coordinate }) => ({
				label: address,
				value: coordinate
			}))
		},
		canApplyFilter() {
			const { location, numOfCouriers } = this.filter
			return location && numOfCouriers > 0
		}
	},
	watch: {
		contact() {
			this.searchByAlias()
		},
		currentAliasType() {
			this.contact = ''
			this.drivers = []
		}
	},
	async created() {
		const { pickupAddress, orderCount } =
			await this.getOrderBatchOptionList()
		this.orderBatchOptions = pickupAddress
		this.orderCount = orderCount
	},
	methods: {
		...mapActions('dashboard', [
			'getOrderBatchOptionList',
			'postOrderBatchList',
			'patchOrderForCourier',
			'postBatchOrderAssign',
			'getOrderBatchDriverInfo'
		]),
		searchByAlias: _.debounce(async function () {
			const isGetByUserNameOrUserId = ['NAME', 'ID'].includes(
				this.currentAliasType
			)
			const threshold = isGetByUserNameOrUserId ? 0 : 5
			if (this.contact.length <= threshold) {
				this.drivers = []
				return
			}
			try {
				const { minPickupAt, locationId } = this.liftedFilter

				const aliasKeyMap = {
					EMAIL: 'email',
					ID: 'userId',
					PHONE: 'phone',
					NAME: 'name'
				}

				const params = {
					locationId,
					shiftStartAt: toDate(minPickupAt)
				}

				params[aliasKeyMap[this.currentAliasType]] = this.contact

				const response = await this.getOrderBatchDriverInfo(params)

				const driverList = Array.isArray(response)
					? response
					: [response]

				this.drivers = driverList.map(driver => {
					const { id } = driver
					const { name = '-' } = driver.info
					const contact =
						_.find(driver.aliases, alias => alias.id).id || '-'
					const shifts = driver.shifts
					return { id, name, contact, shifts }
				})
			} catch (e) {
				logger.error('User search', { category: 'API' }, e.stack)
				this.$q.notify({
					message: e.message,
					type: 'negative',
					position: 'top'
				})
			}
		}, 1000),
		getAliasTypeTranslation(aliasType) {
			switch (aliasType) {
				case 'NAME':
					return 'DRIVER NAME'
				case 'ID':
					return 'DRIVER ID'
				default:
					return aliasType
			}
		},
		async submit() {
			await this.createBatches()
		},
		async confirm() {
			this.loading = true
			try {
				const selectedOrderIds = this.selectedOrderBatch.map(
					({ id }) => id
				)
				const { id, name, contact } = this.currentDriver
				await this.postBatchOrderAssign({
					orderIds: selectedOrderIds,
					userId: id
				})
				this.$q.notify({
					message: `Assigned ${selectedOrderIds.length} orders to ${name}, ${contact} successfully`,
					type: 'positive',
					position: 'top'
				})
				this.orderCount -= selectedOrderIds.length
				const index = _.findIndex(this.orderBatches, batch =>
					_.isEqual(batch, this.selectedOrderBatch)
				)
				if (index > -1) {
					this.orderBatches.splice(index, 1)
				}
				this.selectedOrderBatch = _.get(this.orderBatches, '0', [])
			} catch (e) {
				this.$q.notify({
					message: e.message,
					type: 'negative',
					position: 'top'
				})
			} finally {
				this.loading = false
			}
		},
		async createBatches() {
			const { minPickupAt, maxPickupAt, locationId } = this.liftedFilter
			const { numOfCouriers, location } = this.filter
			this.orderBatches = await this.postOrderBatchList({
				pickupCoordinate: location,
				numOfCouriers: Number(numOfCouriers),
				locationId,
				minPickupAt: toDate(minPickupAt),
				maxPickupAt: toDate(maxPickupAt)
			})
			this.selectedOrderBatch = _.get(this.orderBatches, '0', [])
		},
		formatDriverLabel(driver) {
			const driverName = _.get(driver, 'name', '-')
			const driverContact = _.get(driver, 'contact', '-')
			const driverId = _.get(driver, 'id', '-')
			const driverShifts = Array.isArray(driver.shifts)
				? driver.shifts
						.map(
							shift =>
								`${moment(shift?.shiftStartAt).format(
									'HH:mm'
								)}-${moment(shift?.shiftEndAt)
									.add(1, 's')
									.format('HH:mm')}`
						)
						.join(', ')
				: null
			return `${driverName} | (${driverContact} / ${driverId}) | Shift: ${
				driverShifts || 'N/A'
			}`
		},
		getOrderText(order) {
			const orderRefLabel = _.get(order, 'deliveries.0.orderRef', '-')
			const parcelRefLabel =
				_.chain(order)
					.get('deliveries', [])
					.map('parcelRef')
					.join(', ')
					.value() || '-'
			const shelfId = _.get(
				_.find(order.extras, extra => _.get(extra, 'shelfId')),
				'shelfId',
				'-'
			)
			return `[${order.id}] ORDER: ${orderRefLabel}, PARCEL: ${parcelRefLabel}, SHELFID: ${shelfId}`
		},
		resetBatchDialog() {
			this.filter = { location: null, numOfCouriers: null }
			this.drivers = []
			this.currentDriver = null
			this.currentAliasType = 'PHONE'
			this.contact = '+852'
			this.selectedOrderBatch = []
			this.loading = false
			this.orderBatches = []
		}
	}
}
</script>

<template>
	<q-dialog value @before-hide="$emit('close')">
		<q-card class="dashboard-order-batch-form">
			<q-card-section>
				<h2 class="no-margin">
					Assign Order(s) by Batch
					<span style="float: right">
						Total number of orders: {{ orderCount }}
					</span>
				</h2>
			</q-card-section>
			<q-card-section class="content">
				<q-scroll-area
					style="flex: 0 1 60%"
					visible
					bar-style="background: #757575"
				>
					<h1 v-if="orderBatches.length === 0">
						NO AVAILABLE BATCHES
					</h1>
					<q-list>
						<q-item
							v-for="(batch, index) in orderBatches"
							:key="JSON.stringify(batch)"
							tag="label"
							v-ripple
						>
							<q-item-section avatar>
								<q-radio
									v-model="selectedOrderBatch"
									:val="batch"
									color="primary"
								></q-radio>
							</q-item-section>
							<q-item-section>
								<q-item-label>
									<h2 class="batch-name">
										{{ `Batch ${index + 1}` }}
									</h2>
								</q-item-label>
								<q-item-label caption>
									<q-scroll-area
										style="height: 300px"
										visible
										bar-style="background: #757575"
									>
										<q-list>
											<q-item
												v-for="order in batch"
												:key="order.id"
												class="order-item"
											>
												{{ getOrderText(order) }}
											</q-item>
										</q-list>
									</q-scroll-area>
								</q-item-label>
							</q-item-section>
						</q-item>
					</q-list>
				</q-scroll-area>
				<div>
					<common-form
						layout="vertical"
						:reset="resetBatchDialog"
						:submit="submit"
						submit-label="Batch"
						:disable-btn="!canApplyFilter"
					>
						<div>
							<form-select
								v-model="filter.location"
								:options="pickUpLocationList"
								label="Pick-up Location"
							/>
							<form-input
								type="number"
								v-model="filter.numOfCouriers"
								label="Input number of drivers"
								:rules="
									$validator.positiveNumber(
										'Number of Couriers'
									)
								"
							/>
						</div>
					</common-form>
					<div
						class="assignment-form"
						v-if="selectedOrderBatch.length > 0"
					>
						<h4 class="no-margin">
							Selected orders ({{ selectedOrderBatch.length }} in
							total) will be assigned to
						</h4>
						<div class="alias-types">
							<q-radio
								v-for="aliasType in aliasTypes"
								:key="aliasType"
								v-model="currentAliasType"
								:val="aliasType"
								:label="getAliasTypeTranslation(aliasType)"
							/>
						</div>
						<q-input
							v-model="contact"
							class="driver-alias"
							label="Please input driver alias"
							filled
							dense
							square
						/>
						<q-scroll-area style="flex: 1" visible>
							<div class="driver-list">
								<q-radio
									v-for="driver in drivers"
									:key="driver.id"
									v-model="currentDriver"
									:val="driver"
									:label="formatDriverLabel(driver)"
								/>
							</div>
						</q-scroll-area>
					</div>
				</div>
			</q-card-section>
			<q-card-actions align="right">
				<q-btn
					label="Cancel"
					color="primary"
					flat
					no-caps
					@click="$emit('close')"
				/>
				<div class="q-ml-sm" />
				<tooltip-button
					:disable="
						!(currentDriver && selectedOrderBatch.length !== 0)
					"
					label="Confirm"
					color="primary"
					:tooltips="['At least pick one order and one driver']"
					@click="confirm"
				/>
			</q-card-actions>
			<q-inner-loading :showing="loading">
				<q-spinner color="primary" size="3em" />
			</q-inner-loading>
		</q-card>
	</q-dialog>
</template>

<style lang="scss">
@import '@/assets/css/variables.scss';

.q-card.dashboard-order-batch-form {
	display: flex;
	flex-direction: column;
	width: 1280px;
	max-width: 1280px;
	min-height: 620px;

	.content {
		display: flex;
		column-gap: 30px;
		flex: 1;

		> div {
			display: flex;
			flex-direction: column;
			flex: 1;
		}

		h1 {
			text-align: center;
		}
		h2.batch-name {
			text-align: center;
			font-weight: bold;
		}

		.order-item {
			padding: 5px 5px 5px 20px;
			margin: 0 10px 0 0;
			color: $text-color;

			&:not(:last-child) {
				border-bottom: 1px solid #ddd;
			}

			&:nth-child(even) {
				background: #eee;
			}
		}

		.common-form > div {
			width: 100%;
			> .form-select,
			.form-input {
				width: 100%;
			}
		}

		.assignment-form {
			display: flex;
			flex-direction: column;
			row-gap: 10px;
			flex: 1;

			.alias-types {
				display: flex;

				.q-radio {
					flex: 1;
					justify-content: center;
					padding: 8px;
					border: 1px solid #ddd;

					&:not(:first-child) {
						border-left: 0;
					}

					.q-radio__inner {
						display: none;
					}

					&[aria-checked='true'] {
						background: #f16722;
						color: #fff;
					}
				}
			}

			.q-field .q-field__label {
				font-size: 11px;
			}

			.driver-list {
				display: flex;
				flex-direction: column;

				.q-radio {
					padding: 10px 20px;

					&:not(:last-child) {
						border-bottom: 1px solid #ddd;
					}

					&:nth-child(even) {
						background: #eee;
					}

					&[aria-checked='true'] {
						background: #f16722;
						color: #fff;
					}

					.q-radio__inner {
						display: none;
					}
				}
			}
		}
	}
}
</style>
