<script>
import _ from 'lodash'

export default {
	name: 'Route',
	props: {
		extras: {
			type: Array,
			required: true
		}
	},
	created() {
		this.routeId = _.get(this.extras, '0.routeId', 'N/A')
	}
}
</script>

<template>
	<div class="route">{{ routeId }}</div>
</template>

<style scoped lang="scss">
.route {
	display: flex;
	flex-direction: column;
	justify-content: center;
	row-gap: 3px;
	height: 100%;
	max-width: 300px;

	> div {
		> div {
			white-space: pre-line;
		}
	}
}
</style>
