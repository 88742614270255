<script>
import moment from 'moment-timezone'
import _ from 'lodash'

export default {
	name: 'DeliveryBy',
	props: {
		deliveryBy: {
			type: String,
			required: true
		},
		status: {
			type: String,
			required: true
		},
		isCancelled: {
			type: Boolean,
			required: true
		}
	},
	computed: {
		className() {
			return this.status === 'DELIVERED'
				? ''
				: moment(this.deliveryBy).isBefore(moment())
				? 'text-danger'
				: 'text-warning'
		},
		value() {
			if (!this.deliveryBy) {
				return '-'
			}
			const current = moment()
			const dateTimeString = moment(this.deliveryBy).format(
				'YYYY-MM-DD HH:mm'
			)
			const diff = Math.abs(
				moment(dateTimeString).diff(current, 'minutes')
			)
			const days = Math.floor(diff / (24 * 60))
			const hours = Math.floor((diff / 60) % 24)
			const minutes = diff % 60

			const dateString =
				`${days > 0 ? days + 'd' : ''}` +
				`${hours > 0 ? hours + 'h' : ''}` +
				`${minutes > 0 ? minutes + 'm' : ''}`

			const createExtraString = _.cond([
				[_.isEmpty, _.constant('')],
				[() => this.isCancelled, _.constant('')],
				[() => this.className === 'text-danger', s => `(OVERDUE ${s})`],
				[() => this.className === 'text-warning', s => `(DUE in ${s})`],
				[_.stubTrue, _.constant('')]
			])
			return _.compact([dateTimeString, createExtraString(dateString)])
		}
	}
}
</script>

<template>
	<div :class="className">
		<div v-for="v in value" :key="v">
			{{ v }}
		</div>
	</div>
</template>

<style scoped>
div {
	white-space: normal;
}
</style>
