<script>
import _ from 'lodash'

export default {
	name: 'OrderAddress',
	props: {
		addresses: {
			type: Array,
			required: true
		}
	},
	data() {
		return {
			from: null,
			to: null
		}
	},
	created() {
		const fromContactName = _.get(this.addresses, '0.contacts.0.name', '')
		const fromContactValue = _.get(this.addresses, '0.contacts.0.phone', '')
		const toContactName = _.get(this.addresses, '1.contacts.0.name', '')
		const toContactValue = _.get(this.addresses, '1.contacts.0.phone', '')
		const from = [
			_.get(this.addresses, '0.text', ''),
			`${fromContactName} ${
				fromContactValue ? '- ' + fromContactValue : ''
			}`
		]
		const to = [
			_.get(this.addresses, '1.text', ''),
			`${toContactName} ${toContactValue ? '- ' + toContactValue : ''}`
		]
		this.from = from
		this.to = to
	}
}
</script>

<template>
	<div class="order-address">
		<div v-if="from">
			<div v-for="v in from" :key="v">
				{{ v }}
			</div>
		</div>
		<div v-if="from && to">⬇</div>
		<div v-if="to">
			<div v-for="v in to" :key="v">
				{{ v }}
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
.order-address {
	display: flex;
	flex-direction: column;
	justify-content: center;
	row-gap: 3px;
	height: 100%;
	max-width: 300px;

	> div {
		> div {
			white-space: pre-line;
		}
	}
}
</style>
