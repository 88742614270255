<script>
import moment from 'moment-timezone'
import DeliveryBy from './DeliveryBy.vue'

export default {
	name: 'PickupAtSLA',
	components: { DeliveryBy },
	props: {
		pickupAt: {
			type: String,
			required: true
		},
		deliveryBy: {
			type: String,
			required: true
		},
		status: {
			type: String,
			required: true
		},
		isCancelled: {
			type: Boolean,
			required: true
		}
	},
	computed: {
		formattedPickupAt() {
			if (!this.pickupAt) {
				return ''
			}
			return moment(this.pickupAt).format('YYYY-MM-DD HH:mm')
		}
	}
}
</script>

<template>
	<div class="delivery-stage-type">
		<div>{{ formattedPickupAt }}</div>
		<div v-if="deliveryBy">
			---
			<br />
			<delivery-by
				:delivery-by="deliveryBy"
				:status="status"
				:isCancelled="isCancelled"
			/>
		</div>
	</div>
</template>

<style scoped lang="scss">
.delivery-stage-type {
	display: flex;
	flex-direction: column;
	row-gap: 3px;
}
</style>
